<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" static class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false" :open="sidebarOpen">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-900">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link :to="{ name: item.href }" v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? `${bgbrand800} text-white` : `${textbrand100} hover:${bgbrand600}`, 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                  <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6" :class="`${textbrand300}`" aria-hidden="true" />
                  {{ item.name }}
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden bg-gray-900 md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
          <div class="mt-5 flex-1 flex flex-col">
            <nav class="flex-1 px-2 space-y-1">
              <router-link :to="{ name: item.href }" v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? `${bgbrand800} text-white` : `${textbrand100} hover:${bgbrand600}`, 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                <component :is="item.icon" class="mr-3 flex-shrink-0 h-6 w-6" :class="`${textbrand300}`" aria-hidden="true" />
                {{ item.name }}
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button type="button" class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset md:hidden" :class="`focus:${ringbrand500} `" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex justify-between">

          <div class="ml-4 flex items-center md:ml-6">
             <h1 class="text-2xl font-semibold text-gray-900">Dashboard</h1>
          </div>
        </div>
      </div>

      <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
           
          </div>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <!-- Start Dashboard Content -->
            <div class="py-10">
                
                    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <!-- Form Starts -->
                        <form @submit.prevent="handleSubmit" class="space-y-8 divide-y divide-gray-200">
                            <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div>
                                <div>
                                <h3 class="text-2xl leading-6 font-medium" :class="`${textbrand500}`">
                                    Gallery Photo Upload
                                </h3>
                                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                    The photos uploaded from here will be displayed on your gallery page
                                </p>
                                </div>

                                <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <!-- Vehicle Name  -->
                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label for="vehicle-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Vehicle name
                                    </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div class="max-w-lg flex rounded-md shadow-sm">
                                            <input required type="text" autocomplete="vehicle-name" v-model="vehicleName" class="flex-1 block w-full  min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" :class="`focus:${ringbrand500} focus:${borderbrand500}`" placeholder="ex: 2021 Genesis G80"/>
                                        </div>
                                    </div>
                                </div>
            <!-- Vehicle Photo  -->
                                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label for="vehicle-photo" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Vehicle photo
                                    </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        <div class="space-y-1 text-center">
                                        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <div class="flex text-sm text-gray-600">
                                            <label for="vehicle-upload" class="relative cursor-pointer bg-white rounded-md font-medium  focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 " :class="`${textbrand600} hover:${textbrand500}
                                            focus-within:${ringbrand500}`">
                                            <div class="text-red-500">
                                                {{fileError}}
                                            </div>
                                            <span>Upload vehicle image</span>
                                            <input id="vehicle-upload" @change="handleChange" type="file" class="sr-only" />
                                            </label>
                                            <p class="pl-2 font-bold" >{{fileName}}</p>
                                        </div>
                                        <p class="text-xs text-gray-500">
                                            PNG, JPG up to 1MB
                                        </p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            
            <!-- Install information  -->

                            <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                                <div>
                                <h3 class="text-lg leading-6 font-medium text-gray-900">
                                    Install Information
                                </h3>
                                </div>

                                <div class="space-y-6 sm:space-y-5">

            <!-- Vehicle Type  -->
                                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label for="Vehicle-type" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Vehicle Type
                                        </label>
                                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <select required v-model="VehicleType" id="Vehicle-type" name="Vehicle-type" autocomplete="Vehicle-type" class="max-w-lg block  shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="`focus:${ringbrand500} focus:${borderbrand500} w-full`">
                                            <option>3DR Hatchback</option>
                                            <option>Compact SUV</option>
                                            <option>Coupe</option>
                                            <option>SUV</option>
                                            <option>Sedan</option>
                                            <option>5DR Hatchback</option>
                                            <option>2DR Truck</option>
                                            <option>Station Wagon</option>
                                            <option>Van</option>
                                            <option>4DR Truck</option>
                                            
                                        </select>
                                        </div>
                                    </div>

            <!-- Install Type  -->

                                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label for="install-type" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Install Type
                                        </label>
                                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <select required v-model="installType" id="install-type" name="install-type" autocomplete="install-type" class="max-w-lg block shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="`focus:${ringbrand500} focus:${borderbrand500} w-full `">
                                            <option>Rear Install</option>
                                            <option>Full Install</option>
                                            <option>Front Windows</option>
                                        </select>
                                        </div>
                                    </div>

            <!-- Shade Option Rear  -->

                                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label for="shadeOptionRear" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Shade Option REAR
                                        </label>
                                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <select required v-model="shadeOptionRear" id="shadeOption" name="shadeOption" class="max-w-lg block shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="`focus:${ringbrand500} focus:${borderbrand500} w-full`">
                                            <option>None</option>
                                            <option>5%</option>
                                            <option>20%</option>
                                            <option>30%</option>
                                        </select>
                                        </div>
                                    </div>

            <!-- Shade Option Front  -->

                                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Shade Option FRONT
                                        </label>
                                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <select required v-model="shadeOptionFront" autocomplete="shadeOptionFront" class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="` focus:${ringbrand500} focus:${borderbrand500}`">
                                            <option>None</option>
                                            <option>5%</option>
                                            <option>20%</option>
                                            <option>30%</option>
                                        </select>
                                        </div>
                                    </div>                        

                                </div>
                            </div>
                            </div>

                            <div class="pt-5">

            <!-- Buttons -->
                                
                            <div class="flex justify-end">
                                <button v-if="!isPending" type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2" :class="`${bgbrand600} hover:${bgbrand700}
focus:${ringbrand500}`">
                                Upload
                                </button>
                                <button v-else disabled type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2" :class="`hover:${bgbrand700}
focus:${ringbrand500}`">
                                Uploading...
                                </button>
                            </div>
                            </div>
                        </form>
                    <!-- Form Ends -->
                    </div>
                
            </div>
            <!-- /End Dashboard -->
          </div>
        </div>
      </main>
    </div>
  </div>
  
</template>

<script>
import { ref } from 'vue'
import useStorage from '@/composables/useStorage.js'
import useCollection from '@/composables/useCollection.js'
import { timestamp } from '@/firebase/config'
import configSetup from '@/setup.js'
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
} from '@heroicons/vue/outline'
import { SearchIcon } from '@heroicons/vue/solid'

const settingsIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>

const galleryIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
</svg>

const warrantyIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
</svg>

const galleryListIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
</svg>

const warrantyListIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
</svg>

const pricesIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
</svg>

const calendarIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
</svg>

const navigation = [
  // { name: 'Settings', href: 'Settings', icon: settingsIcon, current: false },
  { name: 'Booking Request', href: 'BookingRequests', icon: calendarIcon, current: false },
  { name: 'Gallery Upload', href: 'ImageLoader', icon: galleryIcon, current: true },
  { name: 'Gallery Edit', href: 'GalleryEdit', icon: galleryListIcon, current: false },
  { name: 'Warranty Creator', href: 'WarrantyCreator', icon: warrantyIcon, current: false },
  { name: 'Warranty Search', href: 'WarrantyList', icon: warrantyListIcon, current: false },
  { name: 'Service Prices', href: 'ServicePrices', icon: pricesIcon, current: false },

]


export default {
    created() {
      const {company} = configSetup()
        document.title = `${company} | Image Loader`
    },

    components: {
        Dialog,
        DialogOverlay,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        BellIcon,
        MenuAlt2Icon,
        SearchIcon,
        XIcon,
    },

    setup(){
        const {
          borderbrand500,
          textbrand100,
          textbrand300,
          textbrand500,
          textbrand600,
          bgbrand600,
          bgbrand700,
          bgbrand800,
          ringbrand500,
          dbGallery,
          } = configSetup()

        const sidebarOpen = ref(false)

        const {url, filePath, uploadImage} = useStorage()
        const {error, addDoc} = useCollection('companyProfile', 'gallery')


        const vehicleName = ref('')
        const VehicleType = ref('')
        const installType = ref('')
        const shadeOptionFront = ref('')
        const shadeOptionRear = ref('')
        const file = ref(null)
        const fileName = ref('')
        const fileSize = ref('')
        const fileError = ref(null)
        const isPending = ref(false)

        // Allowed types
        const types = ['image/png', 'image/jpeg']


        const handleSubmit = async () => {
            if (file.value){
                isPending.value = true
                await uploadImage(file.value, VehicleType.value)
                await addDoc({
                    vehicleName: vehicleName.value,
                    vehicleType: VehicleType.value,
                    installType: installType.value,
                    shadeOptionFront: shadeOptionFront.value,
                    shadeOptionRear: shadeOptionRear.value,
                    imageURL: url.value,
                    filePath: filePath.value,
                    createdAt: timestamp()
                })
                isPending.value = false

                if (!error.value){
                    vehicleName.value = ''
                    VehicleType.value = ''
                    installType.value = ''
                    shadeOptionRear.value = ''
                    shadeOptionFront.value = ''
                    file.value = null
                    fileName.value = ''
                }

            }
            
        }

        const handleChange = (e) => {
            const selected = e.target.files[0]
            if (selected && types.includes(selected.type) && selected.size < 1000000 ){
                file.value = selected
                fileName.value = selected.name
                fileError.value = null
                fileSize.value = selected.size
            } else {
                file.value = null
                fileError.value = 'Please check image format (png or jpg) or image size (max 1 mb)'
            }
        }

    return {
        vehicleName, 
        VehicleType, 
        installType, 
        shadeOptionFront, 
        fileName, 
        fileSize, 
        shadeOptionRear, 
        handleSubmit, 
        handleChange, 
        fileError, 
        isPending,
        navigation,
        sidebarOpen,
        borderbrand500,
        textbrand100,
        textbrand300,
        textbrand500,
        textbrand600,
        bgbrand600,
        bgbrand700,
        bgbrand800,
        ringbrand500,
        dbGallery
        }
    }

}
</script>

<style>

</style>