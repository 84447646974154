import { ref } from "vue";
import { projectFirestore, projectStorage } from "../firebase/config";
import configSetup from "../setup";

const useStorage = () => {
  const error = ref(null);
  const url = ref(null);
  const filePath = ref(null);
  const { companyProfileName } = configSetup();

  const uploadImage = async (file, vehicleType) => {
    filePath.value = `/${companyProfileName}/gallery/${vehicleType}/${file.name}`;
    const storageRef = projectStorage.ref(filePath.value);

    try {
      const res = await storageRef.put(file);
      url.value = await res.ref.getDownloadURL();
    } catch (err) {
      console.log(err.message);
      error.value = err.message;
    }
  };

  const uploadWarrantyImage = async (file, vehicleType) => {
    filePath.value = `warranty/${vehicleType}/${file.name}`;
    const storageRef = projectStorage.ref(filePath.value);

    try {
      const res = await storageRef.put(file);
      url.value = await res.ref.getDownloadURL();
    } catch (err) {
      console.log(err.message);
      error.value = err.message;
    }
  };

  const deleteImage = async (path) => {
    const storageRef = projectStorage.ref(path);
    try {
      await storageRef.delete();
    } catch (error) {
      console.log(error.message);
      error.value = error.message;
    }
  };

  return {
    url,
    filePath,
    error,
    uploadImage,
    uploadWarrantyImage,
    deleteImage,
  };
};

export default useStorage;
