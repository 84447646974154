import { ref } from "vue";
import { projectFirestore } from "../firebase/config";
import configSetup from "../setup";

const { companyProfileName } = configSetup();

const useCollection = (collection, subCollection, newCollection, newDoc) => {
  const error = ref(null);
  const isPending = ref(false);

  // add a new document
  const addDoc = async (doc) => {
    error.value = null;
    isPending.value = true;

    try {
      await projectFirestore
        .collection(collection)
        .doc(companyProfileName)
        .collection(subCollection)
        .add(doc);
      isPending.value = false;
    } catch (err) {
      console.log(err.message);
      error.value = "could not create gallery file";
      isPending.value = false;
    }
  };

  const addNewDoc = async (doc) => {
    error.value = null;
    isPending.value = true;

    try {
      await projectFirestore
        .collection(collection)
        .doc(companyProfileName)
        .collection(subCollection)
        .doc(newCollection)
        .collection(newDoc)
        .add(doc);
      isPending.value = false;
    } catch (err) {
      console.log(err.message);
      error.value = "could not create gallery file";
      isPending.value = false;
    }
  };

  return { error, addDoc, isPending, addNewDoc };
};

export default useCollection;
